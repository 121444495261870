import React, { useEffect, useState } from 'react';
import Sidebar from '../AdminSidebar/Sidebar';
import axios from 'axios';

const ActivationBonus = () => {

const [selfBonusList, setSelfBonus] = useState([]);

    const getBonusList = async() => {
       try {
        const result = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/self-bonus-list`);
            setSelfBonus(result.data);
          console.log(result);
          
       } catch (error) {
        console.log(error);
        
       }
    }


    useEffect(() => {
      getBonusList();
    },[])


    return (
        <div className="flex min-h-screen gap-4">
        <Sidebar className="fixed w-60 h-full"  />
        {/* <div className="ml-60 container mx-auto p-4">
                <div className="flex justify-between items-center bg-green-500 text-white text-sm font-bold px-4 py-3">
                    <h3>Bonus List</h3>
                   
                </div>
                <table className="min-w-full bg-white border border-gray-200">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-center">Sn No.</th>
                            <th className="py-3 px-6 text-center">User Id</th>
                            <th className="py-3 px-6 text-center">Amount</th>
                            <th className="py-3 px-6 text-center">Package</th>
                            <th className="py-3 px-6 text-center">View More</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                        {selfBonusList.length > 0 ? (
                            unpaidUsers.map((user, index) => (
                                <tr key={user._id} className={`border-b border-gray-200 hover:bg-gray-100 ${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'}`}>
                                    <td className="py-3 px-6 text-center">{index + 1}</td>
                                    <td className="py-3 px-6 text-center">{user.user}</td>
                                    <td className="py-3 px-6 text-center">{user.amount}</td>
                                    <td className="py-3 px-6 text-center">{user.package}</td>
                                    <td className="py-3 px-6 text-center">View More</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5" className="text-center py-4">No unpaid users found</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div> */}
        </div>
    );
};

export default ActivationBonus;
