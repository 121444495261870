// // import React, { useState } from "react";
// // import { useAuth } from "../../context/auth";
// // import { useCurrencyAuth } from "../../context/currency";
// // import { useNavigate } from "react-router-dom";
// // import Layout from "../Layout";

// // const Deposite = () => {
// //   const amounts = [540, 1350, 3150, 6750, 11250, 29250];
// //   const [amount, setAmount] = useState(0);
// //   const [auth] = useAuth();
// //   const [currencyAuth, setCurrencyAuth] = useCurrencyAuth();
// //   const navigate = useNavigate();

 

// //   return (
// //     <Layout title={"Recharge - Hype Drinks"}>
// //       <div className="bg-gradient-to-b from-green-400 to-blue-500 pb-20 min-h-screen sm:w-2/5 mx-auto  shadow-xl p-6">
// //         <header className="flex justify-between items-center p-4 shadow-lg rounded-t-lg bg-white">
// //           <div
// //             className="cursor-pointer font-bold text-lg text-blue-700"
// //             onClick={() => navigate(-1)}
// //           >
// //             <img
// //               src={"/images/back.png"}
// //               alt="right arrow"
// //               className="w-10 h-10"
// //             />
// //           </div>
// //           <h1 className="font-bold text-2xl text-gray-800">Recharge</h1>
// //           <div></div>
// //         </header>
// //        {/* ( <div className="mt-6 p-4 bg-white rounded-lg shadow-md">
// //           <h2 className="text-xl font-semibold text-gray-800">Select Amount</h2>
// //           <div className="flex flex-wrap gap-4 mt-4">
// //             {amounts.map((amount) => (
// //               <button
// //                 key={amount}
// //                 onClick={() => setAmount(amount)}
// //                 className={`bg-gradient-to-r from-yellow-400 to-orange-500 text-white font-bold py-2 px-6 rounded-lg shadow-md transform hover:scale-105 transition-transform`}
// //               >
// //                 {currencyAuth === "INR"
// //                   ? amount
// //                   : `$${(amount / 90).toFixed(2)}`}
                
// //               </button>
// //             ))}
// //           </div>
// //           <div className="text-center w-[20%] p-2 rounded-lg text-white m-auto mt-10 mb-2 bg-orange-600">
// //           {
// //               currencyAuth === "INR"
// //                 ? parseInt(amount).toFixed(2)
// //                 : (parseInt(amount) / 90).toFixed(2)
// //             }
// //           </div>
// //         </div>) */}
// //         <div className="mt-6 p-4 bg-white rounded-lg shadow-md">
// //           <h2 className="text-xl font-semibold text-gray-800">
// //             Choose Payment Channel
// //           </h2>
// //           <div className="flex justify-between mt-4 gap-3">
// //             <button
// //             onClick={() => {navigate('/users/user/coin-deposite',{state: {data:amount}})}}
// //               className={`flex-1 p-4 bg-gradient-to-r from-green-400 to-teal-500 text-white font-bold rounded-lg shadow-md transform hover:scale-105 transition-transform `}
// //             >
// //               {"Crypto"}
// //             </button>
// //             <button
// //             onClick={() => {navigate('/users/user/upi-deposite',{state: {data:amount}})}}
// //               className={`flex-1 p-4 bg-gradient-to-r from-green-400 to-teal-500 text-white font-bold rounded-lg shadow-md transform hover:scale-105 transition-transform `}
// //             >
// //               {"UPI"}
// //             </button>
// //             <button
// //             onClick={() => {navigate('/users/user/qr-deposite',{state: {data:amount}})}}
// //               className={`flex-1 p-4 bg-gradient-to-r from-green-400 to-teal-500 text-white font-bold rounded-lg shadow-md transform hover:scale-105 transition-transform`}
// //             >
// //               {"QRCode"}
// //             </button>
// //           </div>
// //         </div>
// //       </div>
// //     </Layout>
// //   );
// // };

// // export default Deposite;










// import React, { useState } from "react";
// import { useAuth } from "../../context/auth";
// import { useCurrencyAuth } from "../../context/currency";
// import { useNavigate } from "react-router-dom";
// import Layout from "../Layout";

// const Deposite = () => {
//   const amounts = [540, 1350, 3150, 6750, 11250, 29250];
//   const [amount, setAmount] = useState(0);
//   const [auth] = useAuth();
//   const [currencyAuth, setCurrencyAuth] = useCurrencyAuth();
//   const navigate = useNavigate();

//   return (
//     <Layout title={"Recharge - Hype Drinks"}>
//       <div className="bg-gradient-to-b from-green-400 to-blue-500 pb-20 min-h-screen sm:w-2/5 mx-auto shadow-xl p-6">
//         <header className="flex justify-between items-center p-4 shadow-lg rounded-t-lg bg-white">
//           <div
//             className="cursor-pointer font-bold text-lg text-blue-700"
//             onClick={() => navigate(-1)}
//           >
//             <img
//               src={"/images/back.png"}
//               alt="right arrow"
//               className="w-10 h-10"
//             />
//           </div>
//           <h1 className="font-bold text-2xl text-gray-800">Recharge</h1>
//           <div></div>
//         </header>
//         {/* Select Amount Section */}
//         <div className="mt-6 p-4 bg-white rounded-lg shadow-md">
//           <h2 className="text-xl font-semibold text-gray-800">Choose Payment Channel</h2>
//           <div className="flex justify-between mt-4 gap-3">
//             <button
//               onClick={() => {
//                 navigate("/users/user/coin-deposite", { state: { data: amount } });
//               }}
//               className={`flex-1 p-4 bg-gradient-to-r from-green-400 to-teal-500 text-white font-bold rounded-lg shadow-md transform hover:scale-105 transition-transform `}
//             >
//               {"Crypto"}
//             </button>
//             <button
//               onClick={() => {
//                 navigate("/users/user/upi-deposite", { state: { data: amount } });
//               }}
//               className={`flex-1 p-4 bg-gradient-to-r from-green-400 to-teal-500 text-white font-bold rounded-lg shadow-md transform hover:scale-105 transition-transform `}
//             >
//               {"UPI"}
//             </button>
//             <button
//               onClick={() => {
//                 navigate("/users/user/qr-deposite", { state: { data: amount } });
//               }}
//               className={`flex-1 p-4 bg-gradient-to-r from-green-400 to-teal-500 text-white font-bold rounded-lg shadow-md transform hover:scale-105 transition-transform`}
//             >
//               {"QRCode"}
//             </button>
//           </div>
//         </div>
//         {/* Instructions Section */}
//         <div className="mt-6 p-4 bg-white rounded-lg shadow-md">
//           <h2 className="text-xl font-semibold text-gray-800">Instructions</h2>
//           <ul className="list-disc ml-5 mt-4 text-gray-700">
//             <li className="mb-2">
//               <strong>Crypto:</strong> Transfer the selected amount using your preferred cryptocurrency. Ensure that the wallet address is entered correctly to avoid loss of funds.
//             </li>
//             <li className="mb-2">
//               <strong>UPI:</strong> Use your UPI app to scan the provided UPI ID or use the UPI handle to make the payment. Ensure that the transaction is completed within the given time frame.
//             </li>
//             <li>
//               <strong>QRCode:</strong> Scan the provided QR code with any payment app to complete the transaction. Make sure to confirm the amount before submitting.
//             </li>
//           </ul>
//         </div>
//       </div>
//     </Layout>
//   );
// };

// export default Deposite;










import React, { useState } from "react";
import { useAuth } from "../../context/auth";
import { useCurrencyAuth } from "../../context/currency";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout";

const Deposite = () => {
  const amounts = [540, 1350, 3150, 6750, 11250, 29250];
  const [amount, setAmount] = useState(0);
  const [auth] = useAuth();
  const [currencyAuth] = useCurrencyAuth();
  const navigate = useNavigate();

  return (
    <Layout title={"Recharge - Hype Drinks"}>
      <div className="bg-gradient-to-b from-green-400 to-blue-500 pb-20 min-h-screen sm:w-2/5 mx-auto shadow-xl p-6">
        <header className="flex justify-between items-center p-4 shadow-lg rounded-t-lg bg-white">
          <div
            className="cursor-pointer font-bold text-lg text-blue-700"
            onClick={() => navigate(-1)}
          >
            <img
              src={"/images/back.png"}
              alt="back arrow"
              className="w-10 h-10"
            />
          </div>
          <h1 className="font-bold text-2xl text-gray-800">Recharge</h1>
          <div></div>
        </header>

        <div className="mt-6 p-4 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold text-gray-800">
            Choose Payment Channel
          </h2>

          {/* Payment Buttons */}
          <div className="flex justify-between mt-4 gap-3">
            <button
              onClick={() =>
                navigate('/users/user/coin-deposite', { state: { data: amount } })
              }
              className="flex-1 p-4 bg-gradient-to-r from-green-400 to-teal-500 text-white font-bold rounded-lg shadow-md transform hover:scale-105 transition-transform"
            >
              Crypto
            </button>

            <button
              onClick={() =>
                navigate('/users/user/upi-deposite', { state: { data: amount } })
              }
              className="flex-1 p-4 bg-gradient-to-r from-green-400 to-teal-500 text-white font-bold rounded-lg shadow-md transform hover:scale-105 transition-transform"
            >
              UPI
            </button>

            <button
              onClick={() =>
                navigate('/users/user/qr-deposite', { state: { data: amount } })
              }
              className="flex-1 p-4 bg-gradient-to-r from-green-400 to-teal-500 text-white font-bold rounded-lg shadow-md transform hover:scale-105 transition-transform"
            >
              QRCode
            </button>
          </div>
          </div>
          {/* Payment Instructions */}
          <div className="mt-6 p-4 bg-white rounded-lg shadow-md">
          <div className="mt-8">
            <h3 className="text-lg font-semibold text-gray-700">Instructions:</h3>

            <ul className="list-disc pl-6 mt-2">
              <li>
                <strong>Crypto Payment:</strong>
                <ul className="list-decimal pl-4 mt-1">
                  <li>Ensure you have a valid cryptocurrency wallet.</li>
                  <li>Transfer the exact amount to the provided wallet address.</li>
                  <li>Wait for confirmation of the transaction on the blockchain.</li>
                </ul>
              </li>

              <li className="mt-4">
                <strong>UPI Payment:</strong>
                <ul className="list-decimal pl-4 mt-1">
                  <li>Open your UPI app and scan the provided UPI ID.</li>
                  <li>Ensure the amount matches the selected recharge value.</li>
                  <li>Confirm the transaction once the UPI request is sent.</li>
                </ul>
              </li>

              <li className="mt-4">
                <strong>QRCode Payment:</strong>
                <ul className="list-decimal pl-4 mt-1">
                  <li>Use your mobile banking app to scan the provided QR code.</li>
                  <li>Verify the transaction details before proceeding.</li>
                  <li>Wait for the confirmation from your bank or payment provider.</li>
                </ul>
              </li>
            </ul>

            {/* Transaction Issue Notice */}
            <p className="text-red-600 mt-6 text-center">
              If there is any issue related to the transaction, please go to the "Contact Manager" section in the "My Profile" page. Contact the admin, and your issue will be resolved within 24 to 48 working hours.
            </p>
          </div>
        </div>
        </div>
    </Layout>
  );
};

export default Deposite;
